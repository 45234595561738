import React from 'react';

import Icon from './Icon';

export default function EditIcon({ className }: { className?: string; }): JSX.Element {
  return (
    <Icon text="Edit">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.8431 3.72041C14.216 3.0932 13.1985 3.0932 12.5713 3.72041L3.7222 12.5702L3.72139 12.571C3.41847 12.8727 3.25 13.2818 3.25 13.7071V16.75H6.29278C6.71809 16.75 7.12719 16.5815 7.42894 16.2785L7.42966 16.2777L16.2796 7.42884C16.9068 6.80166 16.9068 5.78422 16.2796 5.15703L14.8431 3.72041ZM13.632 4.78103C13.6734 4.73966 13.741 4.73966 13.7824 4.78103L15.2189 6.21766C15.2603 6.25905 15.2603 6.32681 15.2189 6.36822L14.0697 7.51736L12.4827 5.93042L13.632 4.78103ZM11.4221 6.99112L13.0089 8.57796L6.3676 15.2185L6.36614 15.22C6.34739 15.2388 6.32139 15.25 6.29278 15.25H4.75V13.7071C4.75 13.6784 4.76122 13.6524 4.78007 13.6336L4.78148 13.6322L11.4221 6.99112Z" />
      </svg>
    </Icon>
  );
}
