import React from 'react';

import Icon from './Icon';

export default function StrokePinnedIcon({
  className = '',
  text = 'StrokePinned',
}: { className?: string; text?: string; }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25 2.58334C5.37579 2.58334 4.66667 3.29246 4.66667 4.16668V4.55418C4.66667 5.08223 4.93076 5.57804 5.37199 5.87189L7.16667 7.06811V8.78076L4.91041 10.3927C4.49491 10.6889 4.25 11.1667 4.25 11.675C4.25 12.5451 4.95495 13.25 5.825 13.25H9.25V16.6667C9.25 17.0809 9.58579 17.4167 10 17.4167C10.4142 17.4167 10.75 17.0809 10.75 16.6667V13.25H14.175C15.045 13.25 15.75 12.5451 15.75 11.675C15.75 11.1667 15.5048 10.6888 15.0894 10.3926L12.8333 8.78076V7.06811L14.6296 5.87086C15.0687 5.57707 15.3333 5.08358 15.3333 4.55418V4.16668C15.3333 3.29246 14.6242 2.58334 13.75 2.58334H6.25Z"
          fill="none"
        />
      </svg>
    </Icon>
  );
}
